import { UseEducationTopicHook } from '../PanelEducate.types'
import { useStaticQuery, graphql } from 'gatsby'

const useEducationTopic: UseEducationTopicHook = (topicId) => {
  // The two current education panels both leverage the same image asset and alt text
  const imageData = useStaticQuery(graphql`
    query {
      image: file(relativePath: { eq: "steak-on-ice.png" }) {
        childImageSharp {
          fluid(maxHeight: 1200, cropFocus: CENTER, rotate: 270) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)
  const imageAlt = 'A New York strip steak resting on a bed of ice'

  switch (topicId) {
    case 'Educate - Defrosting Rules':
      return {
        link:
          'https://justcook.butcherbox.com/the-rules-of-defrosting-and-storing-frozen-meat/',
        title: 'The Rules of Defrosting and Storing Frozen Meat',
        body:
          'At ButcherBox, we’re lucky to have in-house meat industry experts and experienced chefs who can answer our most pressing frozen meat storage and defrosting questions. We put together this guide...',
        image: imageData.image.childImageSharp.fluid,
        altText: imageAlt,
      }
    case 'Educate - Grass Fed Beef':
      return {
        link: 'https://justcook.butcherbox.com/grass-fed-beef/facts-myths/',
        title: 'The Common Misperceptions and Myths about Grass-fed Beef',
        body:
          'Grass-fed beef tastes different based on the pasture the cattle were eating - which means it varies by farm and even time of year.',
        image: imageData.image.childImageSharp.fluid,
        altText: imageAlt,
      }
    default:
      const _exhaustiveCheck: never = topicId
      return _exhaustiveCheck
  }
}

export default useEducationTopic
