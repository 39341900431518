import { Grid } from '@chakra-ui/core'
import { rem } from 'design'
import { ModalHeader } from 'design/components/Modal/Modal'
import { Body } from 'design/components/Typography/Typography'
import React from 'react'
import { CANCEL_SAVE_ACTIONS } from '~/analytics/constants'
import PanelLayout from '~/components/CancelFlowModals/PanelCancelLayout'
import { TEST_ID } from '~/constants/cypress'
import * as Styled from './PanelEducate.styles'
import type * as TYPES from './PanelEducate.types'
import StopMySubscriptionButton from '../../StopMySubscriptionButton/StopMySubscriptionButton'

const PanelEducateUI: React.FC<TYPES.PanelEducateUIProps> = ({
  panel,
  topic,
  onSaveClickHandler,
}) => {
  return (
    <PanelLayout>
      <ModalHeader pb={rem(8)} variant="bare">
        {panel.headline}
      </ModalHeader>
      <Body pb={rem(24)}>{panel.subhead}</Body>

      <Styled.ModalCard>
        <Styled.CardImageLinkWrapper
          href={topic.link}
          onClick={() => onSaveClickHandler(CANCEL_SAVE_ACTIONS.LEARN_MORE)}
          target="blank"
        >
          <Styled.CardImage alt={topic.altText} src={topic.image.src} />
        </Styled.CardImageLinkWrapper>

        <Styled.CardCopyContainer>
          <Styled.CardTitle>{topic.title}</Styled.CardTitle>
          <Styled.CardBody>{topic.body}</Styled.CardBody>
          <Styled.CardLink
            href={topic.link}
            onClick={() => onSaveClickHandler(CANCEL_SAVE_ACTIONS.LEARN_MORE)}
          >
            Learn More
          </Styled.CardLink>
        </Styled.CardCopyContainer>
      </Styled.ModalCard>

      <Grid gap={rem(16)} gridTemplateColumns="1 fr">
        <Styled.ModalCta
          as="a"
          data-cy={TEST_ID.CANCEL_FLOW_SAVE}
          //@ts-ignore
          href={topic.link}
          onClick={() => onSaveClickHandler(CANCEL_SAVE_ACTIONS.READ_THE_GUIDE)}
          target="_blank"
        >
          Read the Guide
        </Styled.ModalCta>
        <StopMySubscriptionButton />
      </Grid>
    </PanelLayout>
  )
}

export default PanelEducateUI
