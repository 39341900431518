import React from 'react'
import { CANCEL_SAVE_ACTIONS } from '~/analytics/constants'
import { trackCancellationSave } from '~/analytics/events'
import { ReachRouterHistoryContext } from '~/context/reachRouterHistory'
import useEducationTopic from './hooks/useEducationTopic'
import PanelEducateUI from './PanelEducate.ui'
import type { CancelPanelProps } from '~/components/CancelFlowPanels/CancelFlowPanel.types'
import type { EducationPanel } from './PanelEducate.types'
const EducateBase: React.FC<
  Omit<CancelPanelProps, 'panel'> & { panel: EducationPanel }
> = ({ panel }) => {
  const educationTopic = useEducationTopic(panel.cancelSaveComponent)
  const { getHistoryEntry } = React.useContext(ReachRouterHistoryContext)

  const makeTrackSaveClickHandler = (
    action: CANCEL_SAVE_ACTIONS.LEARN_MORE | CANCEL_SAVE_ACTIONS.READ_THE_GUIDE
  ) => {
    trackCancellationSave(
      action,
      getHistoryEntry(1).pathname,
      getHistoryEntry(2).state.text
    )
  }

  return (
    <PanelEducateUI
      onSaveClickHandler={makeTrackSaveClickHandler}
      panel={panel}
      topic={educationTopic}
    />
  )
}

export default EducateBase
