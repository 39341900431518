import { rem, styled } from 'design'
import { default as ModalCardComponent } from 'design/components/ModalCard/ModalCard'
import {
  Flex,
  Image as ImageComponent,
  Link as LinkComponent,
} from '@chakra-ui/core'
import { H3 } from 'design/components/Typography/Headlines'
import { Body } from 'design/components/Typography/Body'
import { ModalItemSmall } from '~/components/CancelFlowModals/PanelCancelLayout'

// Card Elements
export const ModalCard = styled(ModalCardComponent)`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  max-height: ${rem(200)};
  max-width: 100%;
  margin-bottom: ${rem(24)};
  min-height: ${rem(200)};
  padding: 0;
  ${(p) => p.theme.media.tablet} {
    min-height: unset;
  }
`
export const CardImageLinkWrapper = styled(LinkComponent)`
  display: none;
  ${(p) => p.theme.media.tablet} {
    display: block;
    height: 100%;
    min-width: ${rem(170)};
    overflow: hidden;
  }
`
export const CardImage = styled(ImageComponent)`
  height: ${rem(304)};
  object-position: 50% ${rem(50)};
  transform: rotate(180deg);
`
export const CardCopyContainer = styled(Flex)`
  flex-direction: column;
  padding: ${rem(24)};
`
export const CardTitle = styled(H3)`
  font-size: ${rem(14)};
  line-height: ${rem(20)};
  padding-bottom: ${rem(8)};
`
export const CardBody = styled(Body)`
  font-size: ${rem(14)};
  line-height: ${rem(20)};
  padding-bottom: ${rem(8)};
`
export const CardLink = styled(LinkComponent)`
  color: ${(p) => p.theme.colors.bb.spicedCrimson};
  font-family: 'Lato';
  font-size: ${rem(14)};
  line-height: ${rem(24)};
  font-weight: bold;
  text-transform: uppercase;
`
// Closing CTAs
export const ModalCta = styled(ModalItemSmall)`
  flex-wrap: wrap;
  &:hover {
    border-color: ${(p) => p.theme.colors.bb.spicedCrimson};
  }
`
